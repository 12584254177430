import React from 'react';
import {Paper, Card, CardMedia, CardContent, Typography } from '@material-ui/core';
const ImageRow = () => {
  return (
  
    <div style={{ display: 'flex', justifyContent: 'center',marginTop: 20  }}>

        <Paper
          style={{
            padding: 24,
            paddingBottom: 24,
            backgroundColor: "#151A1F",
            borderRadius: 13,
            marginRight: 20,
          }}
        >
      <Card style={{ borderRadius: 13, backgroundColor: "#686869" }}>
        <CardMedia>
          <img style={{ borderRadius: 13, height: 250 }} src="/0.gif" alt="Image 1" />
        </CardMedia>
        <CardContent>
          <Typography align="center" variant="body2" color="textSecondary" component="p">
            Motus:Novus
          </Typography>
        </CardContent>
      </Card>
      </Paper>
      <Paper
          style={{
            padding: 24,
            paddingBottom: 24,
            backgroundColor: "#151A1F",
            borderRadius: 13,
            marginRight: 20,
          }}
        >
      <Card style={{ borderRadius: 13, backgroundColor: "#686869" }}>
        <CardMedia>
          <img style={{ borderRadius: 13, height: 250 }} src="/2.gif" alt="Image 1" />
        </CardMedia>
        <CardContent>
          <Typography align="center" variant="body2" color="textSecondary" component="p">
            IV:CRUX
          </Typography>
        </CardContent>
      </Card>
      </Paper>
      <Paper
          style={{
            padding: 24,
            paddingBottom: 24,
            backgroundColor: "#151A1F",
            borderRadius: 13,
            marginRight: 20,
          }}
        >
      <Card style={{ borderRadius: 13, backgroundColor: "#686869" }}>
        <CardMedia>
          <img style={{ borderRadius: 13, height: 250 }} src="/3.gif" alt="Image 1" />
        </CardMedia>
        <CardContent>
          <Typography align="center" variant="body2" color="textSecondary" component="p">
            III:CRUX
          </Typography>
        </CardContent>
      </Card>
      </Paper>
    </div>
  );
}

export default ImageRow;
