import * as React from 'react';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';



export default function BasicCard() {
  return (
    <Card style={{ marginBottom: 25, backgroundColor:'#000000' }} sx={{  minWidth: 275 }}>
      <CardContent>
        <Typography  align="center" sx={{ fontSize: 18 }} color="#f2f5f7" gutterBottom>
         Featured 1/1 Artwork
        </Typography>
        <Typography  color="#f2f5f7"  align="center" variant="h5" component="div">
          Motus:II
        </Typography>
        <Typography  color="#f2f5f7"  align="center" variant="h5" component="div">
          0xCRUX
        </Typography>
      </CardContent>
    </Card>
  );
}